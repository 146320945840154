export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What's news?</p>
      <p className="new__description">
        Aggiornata la sezione bonus e promozioni.
      </p>
      <p className="new__description">
        La posizione della filiale, del bancomat o del terminal più vicino viene
        aggiunta alla mappa.
      </p>
      <p className="new__description">
        Aggiunto il supporto per Face ID e Touch ID.
      </p>
      <p className="new__description">
        Risolve un problema con le notifiche push riscontrato da alcuni utenti
        di Android 10.
      </p>
      <p className="new__description">
        Risolto un problema per cui il testo nelle Impostazioni era troppo
        piccolo per gli utenti Android.
      </p>
    </div>
  );
};
